import React, { useEffect, useState } from "react";
import api from "../api/api";

export default function CheckUserProgress() {
    const [uid, setUid] = useState('');
    const [details, setDetails] = useState(null);
    const [courses, setCourses] = useState([]);
    const [courseLoaded, setCourseLoaded] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState(0);


    useEffect(() => {
        if (!courseLoaded) {
            loadCourses();
        }
    }, [courseLoaded])


    const getUserDetails = () => {
        if (uid && selectedCourse) {
            api.get(`UserCourses/statistics/${uid.trim()}/${selectedCourse}`)
                .then(data => {
                    console.log("loaded")
                    setDetails(() => data)
                })
                .catch(err => {
                    console.log(err, err.message)
                    alert(err)
                })
        }
    }

    const loadCourses = () => {
        api.get(`Courses/alllevels`)
            .then(data => {
                console.log("loaded")
                setCourses(() => data)
                setCourseLoaded(true);
            })
            .catch(err => console.log(err))
    }

    return (
        <div className='p-4'>
            <h5>Type exact user's email</h5>
            <p>
                <input type='email' value={uid} onChange={({ target: { value } }) => setUid(value)} />
                <select onChange={({ target: { value } }) => setSelectedCourse(value)} value={selectedCourse}>
                    <option value={0}>-- Choose course to get statistics for --</option>
                    {courses.map(course => (<option key={course.id} value={course.id}>{course.name}</option>))}
                </select>
                <button type='button' onClick={getUserDetails}>Search</button>
            </p>
            {
                !!details 
                    ? (
                        details.length
                            ? details.map(detail => (<UserDetail key={detail.name} detail={detail} />))
                        : <p>Nothing found</p>
                      )
                    : <p></p>
            }
        </div>
    );
}


const UserDetail = ({ detail }) => {
    return (
        <div className='mb-3 mt-3 p-3' style={{ border: '1px solid black' }}>
            <h6>{detail.name}</h6>
            {
                detail.userModeDetails.map((umd, index) => (
                    <div key={index} className='mb-2 mt-2 p-3' style={{ border: '1px solid red' }}>
                        <p>Mode: {umd.mode}</p>
                        <p>Date taken: {umd.userDetails.dateTaken.split('T')[0]} {umd.userDetails.dateTaken.split('T')[1]}</p>
                        <p>Score: {umd.userDetails.score}</p>
                        <p>Level: {umd.userDetails.level + 1}</p>
                    </div>
                ))
            }
        </div>
    );
}